import { useEffect, useRef, useState } from "react";

import { useIsClient } from "./use-is-client";

const useSticky = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [bannerHeight, setBannerHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const isClient = useIsClient();

  useEffect(() => {
    if (!isClient) return; // Make sure component is rendered
    const handleScroll = () => {
      if (headerRef.current) {
        setIsSticky(window.scrollY > headerRef.current.offsetTop);
      }
    };

    const updateHeights = () => {
      if (bannerRef.current) {
        setBannerHeight(bannerRef.current.offsetHeight);
      }
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateHeights);

    // Initial update of heights
    updateHeights();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateHeights);
    };
  }, [
    isClient,
    headerRef?.current?.offsetHeight,
    bannerRef?.current?.offsetHeight,
  ]);

  return { isSticky, bannerRef, headerRef, bannerHeight, headerHeight };
};

export default useSticky;
