import { useLocation, useRouteLoaderData } from "@remix-run/react";
import React from "react";

import ContactUsButton from "../ContactUsButton";

import useAppState from "~/stores";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { PATH_NAME } from "~/utilities/constants/pathname";

const FloatingButton: React.FC = () => {
  const { locale, phoneNumber } = useRouteLoaderData(
    "root"
  ) as IRouteLoaderData;
  const { showComparedProjectsBar, comparedProjects } = useAppState(
    (state) => state
  );
  const location = useLocation();
  return (
    <div
      className={cn(
        "fixed right-6 z-[40] flex flex-col items-end gap-3",
        comparedProjects.length > 0 &&
          showComparedProjectsBar &&
          PATH_NAME.COMPARE_PROJECTS.all(locale || "") !== location?.pathname
          ? "bottom-[130px] lg:bottom-[150px]"
          : "bottom-6"
      )}
    >
      <ContactUsButton variant="icon" />
      {/* <PhoneFloatingButton phoneNumber={phoneNumber || ""} />
      <WhatsappFloatingButton phoneNumber={phoneNumber || ""} /> */}
      {/* <GreetingFloatingButton /> */}
    </div>
  );
};

export default FloatingButton;
