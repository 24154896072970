import type {
  ActionFunctionArgs,
  LinksFunction,
  LoaderFunctionArgs,
  SerializeFrom,
} from "@remix-run/node";
import {
  isRouteErrorResponse,
  json,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLocation,
  useMatches,
  useNavigation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
// import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFormDataFromSearchParams, parseFormData } from "remix-hook-form";
import { useChangeLanguage } from "remix-i18next/react";
import { getToast, jsonWithSuccess } from "remix-toast";

import Alert from "./components/atoms/Alert";
import ContentGuard from "./components/atoms/ContentGuard";
import FloatingButton from "./components/organisms/FloatingButton";
import FloatingCompareBar from "./components/organisms/FloatingCompareBar/FloatingCompareBar";
import Footer from "./components/organisms/Footer";
import Header from "./components/organisms/Header";
import HeaderMobile from "./components/organisms/HeaderMobile";
import TopBanner from "./components/organisms/TopBanner";
import SectionError from "./components/sections/SectionError";
import { IProject } from "./entities/project";
import { ISalesTeamInfo } from "./entities/salesTeamInfo";
import { getEnv } from "./env.server";
import useSticky from "./hooks/use-sticky";
import { TProjectLoaderData } from "./routes/($lang).projects.$slug/route";
import {
  getCategory,
  getCommonAreaListing,
  getCommonPsfListing,
  // getCommonAreaListing,
  // getCommonPsfListing,
  getLocations,
  getPrices,
  getTOP,
  getUnitTypes,
} from "./services/commonService";
import { getConfigByDomain } from "./services/configService";
import { getProjectsByLocations, getSections } from "./services/projectService";
import { onSubmitUserPreference } from "./services/userPreference";
import useAppState from "./stores";
import { IPaginationReq } from "./types";
import { IRouteLoaderData } from "./types/routeLoaderData";
import { MenuItems, MenuItemsMobile } from "./utilities/config/menuConfig";
import { LIMIT, PAGE } from "./utilities/constants/common";
import { PATH_NAME } from "./utilities/constants/pathname";
import { projectSections } from "./utilities/constants/projectSections";
import { EFormType } from "./utilities/enums/FormType";
import { Slug } from "./utilities/enums/Slug";
import { extractDomainFromRequest } from "./utilities/helpers/requestHelper";
import { formatCurrency } from "./utilities/helpers/string";
import { updateAnchorTags } from "./utilities/helpers/updateAnchorTags";

import { IMetadata } from "~/entities/metadata";
import * as i18n from "~/locales/i18n";
import i18nServer, { localeCookie } from "~/locales/i18n.server";
import stylesheet from "~/tailwind.css?url";
import { cn } from "~/utilities/cn";
import findFirstJpegSource from "~/utilities/helpers/findFirstImage";
import { generateColorVariables } from "~/utilities/helpers/generateColorVariables";
import { getLanguage } from "~/utilities/helpers/getLanguage";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export const handle = { i18n: ["translation"] };

// export const headers = ({ request, params }: LoaderFunctionArgs) => {
//   return {
//     "Document-Policy": "js-profiling", // This allows profiling
//   };
// };

const defaultDomains = ["localhost", "settled-equipped-rat.ngrok-free.app"];

export async function loader({ request, params }: LoaderFunctionArgs) {
  const controller = new AbortController();
  const signal = controller.signal;

  const requestUrl = new URL(request?.url);
  const siteUrl = `${requestUrl.protocol}//${requestUrl.host}`;
  const domain = extractDomainFromRequest(request);
  // setDomainHeader(domain);

  const locale = getLanguage(params);
  const { toast, headers } = await getToast(request);
  const env = getEnv();
  const t = await i18nServer.getFixedT(request);
  console.log("extracted domain", domain);
  const config = await getConfigByDomain(signal, domain);

  const domainConfig = config?.domains?.find((d) => d.primary === true);

  if (!domainConfig?.name) {
    throw new Response(t("error.404.description"), {
      status: 400,
      statusText: t("error.404.message"),
    });
  }
  // Check if the current host contains "highestland"
  if (domainConfig?.name !== domain && !defaultDomains.includes(domain)) {
    // Redirect to the new domain
    const newUrl = requestUrl.toString().replace(domain, domainConfig?.name);

    // Perform a 301 Permanent Redirect
    return redirect(newUrl, 301);
  }

  const projectsByLocations = await getProjectsByLocations(domain);
  const serializedLocaleCookie = await localeCookie.serialize(locale);

  headers.append("Set-Cookie", serializedLocaleCookie);
  if (isEmpty(config?.user)) {
    throw new Response(t("error.404.description"), {
      status: 400,
      statusText: t("error.404.message"),
    });
  }

  const projectMenuItems = [...projectSections];
  const resLocations = await getLocations(domain);
  const resUnitTypes = await getUnitTypes(domain);
  const resCategory = await getCategory(domain);
  const tops = await getTOP(domain);
  const prices = await getPrices(domain);
  const areas: number[] = await getCommonAreaListing(domain);
  const psfs: number[] = await getCommonPsfListing(domain);
  const searchParams = getFormDataFromSearchParams(request) as IPaginationReq;
  const sections = await getSections({
    domain,
    pagination: {
      page: searchParams?.page || PAGE,
      limit: searchParams?.limit || LIMIT,
    },
  });

  const metadata: IMetadata = {
    title: config?.siteMetadata?.title ?? config?.siteTitle,
    description: config?.siteMetadata?.description ?? config?.seoDescription,
    ogImage:
      env.IMAGE_DOMAIN_URL +
      (isEmpty(config?.seoImage)
        ? findFirstJpegSource([config?.coverImage])?.urls[0]?.url
        : findFirstJpegSource([config?.seoImage])?.urls[0]?.url),
    twitterCreator:
      config?.siteMetadata?.twitterCreator ?? config?.twitterCreator,
    twitterSite: config?.siteMetadata?.twitterSite ?? config?.twitterSite,
  };

  const routeLoaderData: IRouteLoaderData = {
    siteUrl,
    config,
    domain,
    ENV: env,
    locale,
    metadata,
    colorScheme: config?.colorScheme,
    phoneNumber: config?.phoneNumber || "",
    logoHeader: config?.headerLogo,
    logoFooter: config?.footerLogo,
    siteLogo: config?.siteLogo,
    whatsapp: config?.whatsapp || "",
    siteDescription:
      typeof config?.siteDescription === "object"
        ? config?.siteDescription?.en
        : config?.siteDescription,
    socialLinks: config?.socialLinks || [],
    topMessage: config?.topMessage,
    userConfig: config?.user,
    topButton: config?.topButton,
    floatingButtonEnabled: config?.floatingButtonEnabled,
    salesTeamInfo: config?.salesTeamInfo || ({} as ISalesTeamInfo),
    siteDisclaimers: config?.siteDisclaimers,
    filterOptions: {
      locations: resLocations?.map((location) => ({
        id: location.slug,
        name: location.name,
      })),
      unitTypes: resUnitTypes?.map((unit) => ({
        id: unit.slug,
        bedRoomCount: `${unit?.bedRoomCount || "0"}`,
        isStudio: `${unit?.isStudio ? "true" : "false"}`,
        name: unit.title,
      })),
      categories: resCategory?.map((category) => ({
        id: category.slug,
        name: category.name,
      })),
      tops: tops?.map((top) => ({
        id: top?.value,
        name: top?.label,
      })),
      prices: prices?.map((price) => ({
        id: `${price}`,
        name: `${formatCurrency(price)}`,
      })),
      area: areas?.map((area) => ({
        id: `${area}`,
        name: `${area.toLocaleString()}`,
      })),
      psf: psfs?.map((psf) => ({
        id: `${psf}`,
        name: `${formatCurrency(psf)}`,
      })),
    },
    projectsByLocations,
    sections,
    projectMenuItems,
    toast,
    formNotice:
      updateAnchorTags(
        config?.siteDisclaimers?.formNotice,
        `https://${domain}/privacy-policy`
      ) || "",
    gaTrackingId: config?.measuringId,
  };

  return json(routeLoaderData, {
    headers,
  });
}

export async function action({ request }: ActionFunctionArgs) {
  try {
    const json = await request.clone().json();

    // submit greeting form
    if (json && json?.type === EFormType.GREETING_FORM) {
      const domain = extractDomainFromRequest(request);
      const response = await onSubmitUserPreference(json?.values, domain);
      if (response) {
        return jsonWithSuccess({ ok: true }, "You submitted successfully!");
      }
      return null;
    }
  } catch (e) {
    // Handle the error appropriately, such as logging or returning a specific response
  }

  const formData = await parseFormData<Record<string, string>>(request, false);
  const url = new URL(request?.url);

  Object.keys(formData)?.forEach((key) => {
    if (formData[key] && formData[key] !== "null") {
      return url.searchParams.set(key, formData[key]);
    }
    return url.searchParams.delete(key);
  });
  const params = url.searchParams.toString();
  return redirect(`${Slug.PROJECTS}?${params}`);
}

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useRouteLoaderData("root") as IRouteLoaderData;
  const location = useLocation();
  const error = useRouteError();
  const matches = useMatches();
  const navigation = useNavigation();

  const isHomePage = location.pathname === "/";

  const isProjectPage = matches.some(
    (match) =>
      match.pathname.startsWith(`${Slug.PROJECTS}/`) &&
      match.pathname !== Slug.PROJECTS
  );
  const isSectionsPage = matches.some(
    (match) =>
      match.pathname.startsWith(`${Slug.SECTIONS}/`) &&
      match.pathname !== Slug.SECTIONS
  );
  const bodyRef = useRef<HTMLBodyElement | null>(null);
  const [areColorsSame, setAreColorsSame] = useState<boolean>(false);

  const { isSticky, bannerRef, headerRef, headerHeight } = useSticky();

  // useEffect(() => {
  //   const handleContextMenu = (event: MouseEvent) => event.preventDefault();
  //   const handleCopy = (event: ClipboardEvent) => event.preventDefault();
  //   const handlePaste = (event: ClipboardEvent) => event.preventDefault();
  //   const handleCut = (event: ClipboardEvent) => event.preventDefault();
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.ctrlKey && (event.key === "p" || event.key === "s")) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("copy", handleCopy);
  //   document.addEventListener("paste", handlePaste);
  //   document.addEventListener("cut", handleCut);
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("copy", handleCopy);
  //     document.removeEventListener("paste", handlePaste);
  //     document.removeEventListener("cut", handleCut);
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  const projectRoute = matches.find(
    (match) => match.id === "routes/($lang).projects.$slug"
  );

  useEffect(() => {
    // Only run this logic on the client side (after hydration)
    if (typeof window !== "undefined") {
      // Check if the current path is the home page ('/')
      if (location.pathname === "/") {
        if (headerRef.current && bodyRef.current) {
          // Get the computed background color of the header and the body
          const headerBackgroundColor = window.getComputedStyle(
            headerRef.current
          ).backgroundColor;
          const bodyBackgroundColor = window.getComputedStyle(
            bodyRef.current
          ).backgroundColor;

          // The target color to check (rgb(241, 240, 238))
          const targetColor = "rgb(241, 240, 238)";

          // Compare the header, body background colors, and target color
          const isColorSame =
            headerBackgroundColor === bodyBackgroundColor &&
            headerBackgroundColor === targetColor;

          // Set the result state
          setAreColorsSame(isColorSame);
        }
      }
    }
  }, [headerRef, location.pathname]); // Only run this effect when the route changes

  const projectRouteData = projectRoute?.data as unknown as TProjectLoaderData;

  // Assuming the loader of that route returns an object with 'project'
  const project = projectRouteData?.project as IProject;

  const faviconLinks = useMemo(() => {
    if (loaderData?.siteLogo?.urls?.[0]?.url) {
      const sizes: string[] = ["16x16", "32x32", "128x128", "192x192"];
      return sizes.map((size, index) => (
        <link
          key={size + index}
          rel="icon"
          href={`${loaderData?.ENV?.IMAGE_DOMAIN_URL}${loaderData?.siteLogo?.urls?.[0]?.url}`}
          sizes={size}
        />
      ));
    }
    return null;
  }, [loaderData?.ENV?.IMAGE_DOMAIN_URL, loaderData?.siteLogo?.urls]);

  if (isRouteErrorResponse(error)) {
    return (
      <html lang={i18n.fallbackLng}>
        <head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          {faviconLinks}
          <Meta />
          <Links />
          <title>404 Not Found</title>
        </head>
        <body
          className="flex min-h-screen flex-col bg-background"
          suppressHydrationWarning={true}
        >
          <div className="flex flex-1 flex-col items-center justify-center bg-backgroundPageProject pt-2">
            {children}
          </div>
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    );
  }

  return (
    <html lang={loaderData?.locale ?? i18n.fallbackLng}>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {faviconLinks}
        {/* <Meta /> */}
        <Links />
        <style
          dangerouslySetInnerHTML={{
            __html: `:root { ${generateColorVariables(
              loaderData?.colorScheme
            )} }`,
          }}
        />
        <title>{loaderData?.config?.siteTitle}</title>
      </head>
      <body
        ref={bodyRef}
        className={cn(
          `flex min-h-screen flex-col bg-background`,
          (isProjectPage || isSectionsPage) && "bg-backgroundPageProject"
        )}
        suppressHydrationWarning={true}
      >
        <div id="modal-root" className="z-[110]"></div>
        {!loaderData?.gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${loaderData?.gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${loaderData?.gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        {!isSectionsPage && (
          <>
            <TopBanner ref={bannerRef} topMessage={loaderData?.topMessage} />
            <Header
              ref={headerRef}
              logo={loaderData?.logoHeader}
              menus={MenuItems}
              topButton={loaderData?.topButton}
              areColorsSame={areColorsSame}
            />
          </>
        )}
        {!isSectionsPage && (
          <HeaderMobile
            ref={headerRef}
            logo={loaderData?.logoHeader}
            menus={MenuItemsMobile}
            projectMenuItems={loaderData?.projectMenuItems}
            phoneNumber={loaderData?.phoneNumber || ""}
            socialLinks={loaderData?.socialLinks || []}
            topButton={loaderData?.topButton}
            top={headerHeight}
            isSticky={isSticky}
            project={project}
            areColorsSame={areColorsSame}
          />
        )}
        {navigation.state === "loading" && (
          <div className="min-w-screen fixed z-50 flex h-full min-h-screen w-full items-center justify-center bg-background opacity-60">
            <span className="loading loading-dots loading-lg text-success"></span>
          </div>
        )}
        <div
          className={cn(
            "flex-1 pt-4",
            (isHomePage || isProjectPage) && "lg:pt-0",
            isSectionsPage && "flex items-center justify-center"
          )}
        >
          {/* {children} */}
          <ContentGuard>{children}</ContentGuard>
        </div>

        {!isSectionsPage && (
          <Footer
            logo={loaderData?.logoFooter}
            phoneNumber={loaderData?.phoneNumber || ""}
            socialLinks={loaderData?.socialLinks || []}
            siteTitle={loaderData?.config?.siteTitle || ""}
            siteDescription={
              (loaderData?.siteDescription as unknown as string) || ""
            }
            disclaimer={loaderData?.siteDisclaimers.footerDisclaimer}
            domain={loaderData?.domain || ""}
            locations={loaderData?.projectsByLocations}
          />
        )}
        <ScrollRestoration />
        {PATH_NAME.COMPARE_PROJECTS.all(loaderData?.locale || "") !==
          location?.pathname && (
          <FloatingCompareBar
            classNameContainer="sticky bottom-0 left-0 z-50"
            locale={loaderData?.locale || ""}
          />
        )}
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData?.ENV)};`,
          }}
        />
      </body>
    </html>
  );
}

function App() {
  const { locale, toast, floatingButtonEnabled } = useRouteLoaderData(
    "root"
  ) as IRouteLoaderData;
  const [isOpenAlert, setOpenAlert] = useState(false);
  const { alertMessage, alertOpen, setAlert } = useAppState((state) => state);
  const { t } = useTranslation();
  const matches = useMatches();
  const isSectionsPage = matches.some(
    (match) =>
      match.pathname.startsWith(`${Slug.SECTIONS}/`) &&
      match.pathname !== Slug.SECTIONS
  );

  useEffect(() => {
    if (toast) {
      setOpenAlert(true);
    }
  }, [toast]);

  useChangeLanguage(locale);

  return (
    <>
      <Outlet />
      <Alert
        isOpen={isOpenAlert || alertOpen}
        onClose={() => {
          setOpenAlert(false);
          setAlert(false, "");
        }}
        title={t(toast?.message || "") || alertMessage}
        variants="success"
      />
      {!isSectionsPage && floatingButtonEnabled && <FloatingButton />}
    </>
  );
}

// export default withSentry(App);
export default App;

export function ErrorBoundary() {
  const error = useRouteError();

  const getErrorCode = () => {
    if (isRouteErrorResponse(error)) {
      return "404";
    } else if (error instanceof Error) {
      return "500";
    }
    return "500";
  };

  // captureRemixErrorBoundaryError(error);

  return <SectionError errorCode={getErrorCode()} error={error} />;
}

export type TLoaderData = SerializeFrom<typeof loader>;
